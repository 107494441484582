@import '@src/vars'

.b-border-form-delete-button-wrapper
  button
    position relative
    width 40px
    height 40px
    span
      position absolute
      top 50%
      left 50%
      font-size $font-size-xl
      transform translate(-50%, -50%)

.b-border-form
  .t-form-control-error-message
    white-space nowrap

.b-border-form-id
  display flex
  align-items center
  height 40px
  font-size $font-size-xs
  font-weight bold
