@import url('https://rsms.me/inter/inter.css');
@import 'vars'
@import 'mixins'

html,
body
  margin 0
  color $color-grey-dark
  font-size 16px
  font-family $font-family-primary
  font-weight 300

h1, h2, h3, h4, h5, h6
  margin 0

h1
  font-size $font-size-xl
  letter-spacing -1px

h2
  font-size $font-size-xl

h3
  font-size $font-size-md

p
  margin 0
  line-height 1.2

a
  color $color-blue
  text-decoration none
