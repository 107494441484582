@import '@src/vars.styl'

.b-border-preview-container
  margin 0 0 40px
  display flex
  justify-content center
  padding 60px 0
  text-indent -9999px
  border-bottom 1px solid $color-grey-light

.b-border-preview
  height 100px
  width 100px
  border-radius 10%
